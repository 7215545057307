<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Add a Salary Payment</p>
        <v-row>
          <v-col cols="10" class="py-0 my-1">
            <v-text-field
              v-model="userName"
              ref="userName"
              outlined dense
              label="Search Employee..."
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="py-0 my-1">
            <v-btn
              color="success"
              class="me-3"
              fab small
              @click="getEmployee()"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-select
              v-model="selectedClient"
              ref="selectedClient"
              :items="clients"
              item-text="username"
              item-value="id"
              outlined dense
              label="Select Employee"
            ></v-select>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <v-text-field
              v-model="payment_means"
              ref="payment_means"
              outlined dense
              label="Input Payment Means..."
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="amount_paid"
              ref="amount_paid"
              outlined
              dense
              label="Enter Amount Paid"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="py-0 my-1"
          >
            <v-text-field
              v-model="amount_owing"
              ref="amount_owing"
              outlined
              dense
              label="Enter Amount Owing"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <v-text-field
              v-model="month_year"
              type="date" 
              ref="month_year"
              outlined dense
              label="Enter Payment Date"
            ></v-text-field>
          </v-col>

        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="addEmployeePayment()"
        >
          Add Payment
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {

    return {
      loading: false,
      userName: '',
      clients: [],
      selectedClient: "",

      payment_means: "",
      amount_paid: "",
      amount_owing: "",
      month_year: "",

      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | Add Employee Payments"
  },

  methods:{
    getEmployee(){
      axios
        .get('/api/v1/manager/get/clients/'+this.userName+'/')
        .then(response => {
          this.clients = response.data  // get the data and fill into campaigns
          this.selectedClient = response.data[0]["id"]  // get the data and fill into campaigns
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    async addEmployeePayment(){
      // console.log(this.resourceFile)
      if(this.payment_means === ""){
          this.$store.commit('setSnackBarMessage', "Please fill in the means of payment")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.payment_means.focus()  // this causes a an auto fucos to the element
      }
      else if(this.amount_paid === ""){
          this.$store.commit('setSnackBarMessage', "Please input the amount paid")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.amount_paid.focus()  // this causes a an auto fucos to the element
      }
      else if(this.amount_owing === ""){
          this.$store.commit('setSnackBarMessage', "Please input the amount left to pay")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.amount_owing.focus()  // this causes a an auto fucos to the element
      }
      else if(this.month_year === ""){
          this.$store.commit('setSnackBarMessage', "Please input the day of payment")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.month_year.focus()  // this causes a an auto fucos to the element
      }
      else if(this.selectedClient === ""){
          this.$store.commit('setSnackBarMessage', "Please select the partner")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.selectedClient.focus()  // this causes a an auto fucos to the element
      }
      else {
          this.loading = true

          let formData = new FormData()
          formData.append('paid_employee', this.selectedClient)
          formData.append('payment_means', this.payment_means)
          formData.append('amount_paid', this.amount_paid)
          formData.append('amount_owing', this.amount_owing)
          formData.append('month_year', this.month_year)

          await axios
              .post('/api/v1/manager/add/salary_payment/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
              .then(response => {
                  this.$store.commit('setSnackBarMessage', response.data[0].text)
                  this.$store.commit('setSnackBarColor', response.data[0].color)
                  this.$store.commit('activateSnackBar', true)
                  if(response.data[0].id !== 0){
                      let router = this.$router
                      setTimeout(function(){
                          router.push({ name: 'savings.employees_payments'})
                      }, 2000)
                  }
              })
              .catch(error => {
                  if (error.response){
                      this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }else{
                      this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }
              })

          this.loading = false
      }
    },
  },
}
</script>
